.cFooterWrapper{
    width: 100%;
    margin-top: 5rem;
}

.cFooterWrapper>hr{
    width: 100%;
    height: 1px;
}
.cFooter{
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.logo{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.logo>img{
    width: 2.5rem;
    height: 2.5rem;
}
.logo>span{
    font-weight: 600;
    font-size: 1rem;
}
.block{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}
.detail{
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: 0.7rem;
    gap: 1rem;
    font-style: italic;
}
.detail>span:nth-of-type(1){
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
}
.pngLine:hover{
    display: flex;
    gap: 1rem;
    align-items: center;
}
.icon{
    width: 25px;
}